<template>
  <span v-if="loaded">
    <v-card-title
      class="px-2 py-0 mb-2  paper--text charcoal charcoalTile "
      @click.self="vitaminsDetails = !vitaminsDetails"
    > <span :class="textSize">
        Vitamins
      </span>
      <v-spacer />
      <v-checkbox
        v-model="vitaminsDetails"
        color="paper"
        dark
        off-icon="mdi-chevron-down-circle-outline"
        on-icon="mdi-chevron-up-circle-outline"
        :label="vitaminsDetails ? 'Less' : 'More'"
        title="More Vitamins"
      />
    </v-card-title>
    <v-sheet
      v-if="vitaminsDetails"
      rounded
      class="transparent charcoalTile mt-n2 mb-2 px-2"
    >
      <Nutrient
        :weight="dailyNutrients.dv_vita"
        :proc="dailyNutrients.vita_p"
        name="A"
        weight_unit="mcg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_vitc"
        :proc="dailyNutrients.vitc_p"
        name="C"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_vitd"
        :proc="dailyNutrients.vitd_p"
        name="D"
        weight_unit="mcg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_vite"
        :proc="dailyNutrients.vite_p"
        name="E"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_vitk"
        :proc="dailyNutrients.vitk_p"
        name=" K"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_thia"
        :proc="dailyNutrients.thia_p"
        name="B1 (Thiamin)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_ribf"
        :proc="dailyNutrients.ribf_p"
        name="B2 (Riboflavin)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_nia"
        :proc="dailyNutrients.nia_p"
        name="B3 (Niacin)"
        weight_unit="mg"
      />
      <Nutrient
        v-if="showUntracked"
        :weight="dailyNutrients.dv_vitb5"
        :proc="dailyNutrients.vitb5_p"
        name="B5 (Panthothenic Acid)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_vitb6"
        :proc="dailyNutrients.vitb6_p"
        name="B6"
        weight_unit="mg"
      />
      <Nutrient
        v-if="showUntracked"
        :weight="dailyNutrients.dv_vitb7"
        :proc="dailyNutrients.vitb7_p"
        name="B7 (Biotin)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_vitb12"
        :proc="dailyNutrients.vitb12_p"
        name="B12 (Cobalamin)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_foldfe"
        :proc="dailyNutrients.foldfe_p"
        name="Folate"
        weight_unit="mcg"
      />
    </v-sheet>
    <v-card-title
      class="px-2 py-0 mb-2 paper--text charcoal  charcoalTile "
      @click.self="mineralsDetails = !mineralsDetails"
    >
      <span :class="textSize">
        Minerals
      </span>
      <v-spacer />
      <v-checkbox
        v-model="mineralsDetails"
        color="paper"
        dark
        off-icon="mdi-chevron-down-circle-outline"
        on-icon="mdi-chevron-up-circle-outline"
        :label="mineralsDetails ? 'Less' : 'More'"
        title="More Minerals"
      />
    </v-card-title>
    <v-sheet
      v-if="mineralsDetails"
      rounded
      class="transparent charcoalTile mt-n2 mb-2 px-2"
    >
      <Nutrient
        :weight="dailyNutrients.dv_na"
        :proc="dailyNutrients.na_p"
        name="NA (Sodium)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_ca"
        :proc="dailyNutrients.ca_p"
        name="CA (Calcium)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_k"
        :proc="dailyNutrients.k_p"
        name="K (Potasium)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_mg"
        :proc="dailyNutrients.mg_p"
        name="MG( Magnesium)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_zn"
        :proc="dailyNutrients.zn_p"
        name="ZN (Zinc)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_fe"
        :proc="dailyNutrients.fe_p"
        name="FE (Iron)"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_p"
        :proc="dailyNutrients._p"
        name=" P (Phosphorus)"
        weight_unit="mg"
      />
      <Nutrient
        v-if="showUntracked"
        :weight="dailyNutrients.dv_i"
        :proc="dailyNutrients.i_p"
        name="I (Iodine)"
        weight_unit="mcg"
      />
      <Nutrient
        v-if="showUntracked"
        :weight="dailyNutrients.dv_se"
        :proc="dailyNutrients.se_p"
        name="SE (Selenium)"
        weight_unit="mcg"
      />
      <Nutrient
        v-if="showUntracked"
        :weight="dailyNutrients.dv_co"
        :proc="dailyNutrients.co_p"
        name="CO (Copper)"
        weight_unit="mg"
      />
      <Nutrient
        v-if="showUntracked"
        :weight="dailyNutrients.dv_cr"
        :proc="dailyNutrients.cr_p"
        name="CR (Chromium)"
        weight_unit="mcg"
      />
      <Nutrient
        v-if="showUntracked"
        :weight="dailyNutrients.dv_mo"
        :proc="dailyNutrients.mo_p"
        name="MO (Molybdenum)"
        weight_unit="mcg"
      />
      <Nutrient
        v-if="showUntracked"
        :weight="dailyNutrients.dv_cl"
        :proc="dailyNutrients.cl_p"
        name="CL (Chloride)"
        weight_unit="mg"
      />
    </v-sheet>
    <v-card-title
      class="px-2 py-0 mb-2 paper--text charcoal  charcoalTile"
      @click.self="otherDetails = !otherDetails"
    >
      <span :class="textSize">
        Other
      </span>
      <v-spacer />
      <v-checkbox
        v-model="otherDetails"
        color="paper"
        dark
        off-icon="mdi-chevron-down-circle-outline"
        on-icon="mdi-chevron-up-circle-outline"
        :label="otherDetails ? 'Less' : 'More'"
        title="Other Nutrients"
      />
    </v-card-title>
    <v-sheet
      v-if="otherDetails"
      rounded
      class="mt-n2 mb-2 px-2 transparent charcoalTile"
    >
      <Nutrient
        v-if="showUntracked"
        :weight="dailyNutrients.dv_chol"
        :proc="dailyNutrients.chol_p"
        name="Choline"
        weight_unit="mg"
      />
      <Nutrient
        :weight="dailyNutrients.dv_chole"
        :proc="dailyNutrients.chole_p"
        name="Cholesterol"
        weight_unit="mg"
      />
    </v-sheet>
  </span>
</template>
<script>
import util from '@/mixins/util.js'
const Nutrient = () => import('@/components/blocks/Nutrient.vue')
export default {
  mixins: [util],
  props: {
    dailyNutrients: {
      type: Object
    }
  },
  components: { Nutrient },
  name: 'Nutrients',
  data: () => ({
    loaded: false,
    vitaminsDetails: false,
    mineralsDetails: false,
    otherDetails: false,
    showUntracked: false
  }),
  beforeMount() {
    this.loaded = this.dailyNutrients != null
  },
  updated() {
    this.vita = this.dailyNutrients.vita
    this.vita_p = this.dailyNutrients.vita_p
  }
}
</script>
